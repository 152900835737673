import React from 'react'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import HeaderAbout from '../components/HeaderAbout'

class About extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet title="About Us" />
        <HeaderAbout />
        <div id="main">
          <section id="content" className="main">
            <p>
              The Ancient and Accepted Scottish Rite of Freemasonry is commonly
              known as the Scottish Rite. It is one of several appendant groups
              of the worldwide fraternity known as Freemasonry. Each Valley has
              up to four Scottish Rite bodies, and each body confers a set of
              degrees. In the Southern Jurisdiction these are the Lodge of
              Perfection (4°-14°), Chapter of Rose Croix (15°-18°), Council of
              Kadosh (19°-30°), and the Consistory (31°-32°). The Supreme
              Council confers the 33<sup>rd</sup> Degree of Sovereign Grand
              Inspector General.
            </p>
            <h2>Mission Statement</h2>
            <p>
              It is the mission of the Scottish Rite of Freemasonry, S.J., to
              improve its members, and enhance the communities in which they
              live by teaching and emulating the principles of Brotherly Love,
              Tolerance, Charity and Truth while actively embracing high social,
              moral and spiritual values including fellowship, compassion and
              dedication to God, family and country.
            </p>
            <h2>Strategic Objectives:</h2>
            <ul>
              <li>
                Fulfill the promise of additional Masonic knowledge through
                education and training.
              </li>
              <li>
                Build a Positive Public Image of Freemasonry and the Scottish
                Rite.
              </li>
              <li>Support and expand our philanthropic activities.</li>
              <li>
                Provide a framework for effective leadership to ensure the
                stability and long-term success of the Fraternity.
              </li>
              <li>
                Provide a financial process to ensure the stability and
                long-term success of the Fraternity.
              </li>
            </ul>
            <h2>Allegiance</h2>
            <p>
              The bodies of the Ancient and Accepted Scottish Rite of
              Freemasonry, sitting in the Valley of Baltimore, Orient of
              Maryland, acknowledge and yield allegiance to the Supreme Council
              (Mother Council of the World) of the Inspectors-General, Knights
              Commander of the House of the Temple of Solomon, of the
              Thirty-Third and Last Degree of the Ancient and Accepted Scottish
              Rite of Freemasonry, for the Southern Jurisdiction of the United
              States of America, whose See is at the Grand Orient of Charleston,
              in the State of South Carolina, now sitting at Washington, D.C.
            </p>
            <ul className="actions">
              <li>
                <Link to="/" className="button">
                  Return Home
                </Link>
              </li>
            </ul>
          </section>
        </div>
      </Layout>
    )
  }
}

export default About
